import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import LeadStreamer from '../components/primary/LeadStreamer'
import ExpandableImage from '../components/ExpandableImage'
import PriceTable from '../components/primary/PriceTable'
import DemoVideo from '../components/primary/DemoVideo'
import CallToAction from '../components/primary/CallToAction'
import Newsletter from '../components/Newsletter'
import LeadSpot from '../components/solutions/LeadSpot'

import { isEven } from '../utils/num'

const TechnicalSearchPage = () => {
  const { markdownRemark, productAttributesImage } = useStaticQuery(graphql`
    query TechnicalSearchPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/technical-data-search" } }) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          section3 {
            title
            text
            image {
              modal: childImageSharp {
                ...SharpImageModalFragment
              }
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          benefitsYou {
            blurbsOne {
              title
              text
              icon
            }
            blurbsTwo {
              title
              text
            }
          }
          ...PricingFragment
          ...DemoVideoFragment
          ...CallToActionFragment
        }
      }
      productAttributesImage: file(
        relativePath: {
          eq: "solutions/technical-data-search/product-attributes.jpg"
        }
      ) {
        modal: childImageSharp {
          ...SharpImageModalFragment
        }
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-solutions p-solutions--wholesalers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        imageFolder="technical-data-search"
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        title={pageData.lead.streamer.title}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
      />

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left">
              {pageData.section3.map((blurb, i) => (
                <div key={`section3-${i}`} className="row">
                  <div
                    className={`col-12 col-md-6 mb-5 ${
                      !isEven(i) ? 'order-md-2' : ''
                    } d-flex flex-column justify-content-center`}
                  >
                    <h2 className="mb-4">{blurb.title}</h2>
                    <div className="c-postStreamer__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 text-center">
                    <ExpandableImage
                      imageData={blurb.image.modal}
                      bottomRight={isEven(i)}
                    >
                      <Img
                        fluid={blurb.image.childImageSharp.fluid}
                        title={blurb.title}
                        alt={blurb.title}
                        className="c-postStreamer__image img-fluid"
                      />
                    </ExpandableImage>
                  </div>
                </div>
              ))}
              <div className="row">
                <div
                  className={`col-12 col-lg-8 mb-5 d-flex flex-column justify-content-center`}
                >
                  <h2 className="mb-4">Product Attributes</h2>
                  <p>
                    You’ll find all the specifications you need with our
                    Technical Data Search, including:
                  </p>
                  <div className="c-postStreamer__text p-0">
                    <ul style={{ listStyle: 'none' }}>
                      <li>Inner pack, outer case and pallet values captured</li>
                      <li>
                        Identifiers descriptions, codes (vendor’s. wholesaler’s
                        and GTIN’s (Barcodes) plus category information)
                      </li>
                      <li>Logistics e.g. dimensions and weights</li>
                      <li>Country of Origin</li>
                      <li>Waste Packaging including transport</li>
                      <li>Handling & Storage instructions</li>
                      <li>Product Imagery</li>
                      <li>
                        Allergens, legally required EU 14 major food allergens
                        plus nut and cereal sub groups
                      </li>
                      <li>Nutritional data (Front and back of pack)</li>
                      <li>
                        Diet Suitability (Vegetarian, Vegan, Halal, Kosher,
                        Coeliac, Lactose intolerant)
                      </li>
                      <li>Accreditation & Certification (e.g BRC)</li>
                      <li>Assurance Schemes (e.g. Red Tractor)</li>
                      <li>Microbiological & Analytical Standards</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-5 text-center d-flex flex-column justify-content-center">
                  <ExpandableImage
                    imageData={productAttributesImage.modal}
                    bottomRight={false}
                  >
                    <Img
                      fluid={productAttributesImage.childImageSharp.fluid}
                      title="Product Attributes"
                      // alt={blurb.title}
                      className="c-postStreamer__image img-fluid"
                    />
                  </ExpandableImage>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits-to-you"
        className="c-section c-section--forBenefits c-section--forBenefits--you"
      >
        <div className="c-section__outer container">
          <div className="c-section__tag">Also Included</div>
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.benefitsYou.blurbsOne.map((blurb) => (
                <div key={blurb.title} className="c-forBenefitsInfo row">
                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--icon col-12 col-md-3">
                    <img
                      className="c-forBenefitsInfo__icon img-fluid"
                      src={`/images/icons/${blurb.icon}.svg`}
                    />
                  </div>

                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--text col-12 col-md-7">
                    <h2 className="c-forBenefitsInfo__header">{blurb.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <PriceTable pricing={pageData.pricing} />

      <DemoVideo
        header={pageData.demoVideo.header}
        text={pageData.demoVideo.text}
        buttonText={pageData.demoVideo.buttonText}
        buttonLink={pageData.demoVideo.buttonLink}
        videoUrl={pageData.demoVideo.videoUrl}
      />

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default TechnicalSearchPage
